import api from "../../config/axios";

export const signUp = async (
  firstname,
  lastName,
  email,
  mobile,
  useRole,
  otp
) => {
  try {
    const response = await api.post(`/signup`, {
      username: `${firstname}${" "}${lastName}`,
      email: email,
      mobile: mobile,
      user_role: useRole,
      otp: otp,
    });

    return response.data;
  } catch (error) {
    console.log(error.response.data);

    if (error.response && error.response.data) {
      if (error.response.data === "OTP incorrect") {
        return {
          signup: "OTP incorrect",
          message: "Incorrect OTP, Please try again.",
        };
      } else {
        return {
          signup: "user_exists",
          message: error.response.data.message,
        };
      }
    }
    throw error;
  }
};

export const login = async (email, otp) => {
  try {
    const response = await api.post(`/login`, {
      username: email,
      otp: otp,
    });
    return response.data;
  } catch (error) {
    console.log(error.response.data);
    error.message = error.response && error.response.data;
    // error.message = error.response && error.response.data.message;
    throw error;
  }
};

export const sendOTP = async (email) => {
  try {
    const response = await api.post(`/v1/send_mail_for_login`, {
      email: email,
    });
    return response.data;
  } catch (error) {
    error.message = error.response && error.response.data.message;
    throw error;
  }
};

export const addUser = async (user) => {
  try {
    const response = await api.post(`/signup`, {
      username: user.username,
      email: user.email,
      mobile: user.mobile,
      user_role: user.role,
      signup_type: "admin",
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        signup: "user_exists",
        message: error.response.data.message,
      };
    }
    throw error;
  }
};

export const addCandidate = async (candidate) => {
  try {
    const response = await api.post(`/signup`, {
      username: candidate.username,
      email: candidate.email,
      mobile: candidate.mobile,
      user_role: "candidate",
      otp: candidate.otp || "0000", // Assuming default OTP is "0000" if not provided
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        signup: "user_exists",
        message: error.response.data.message,
      };
    }
    throw error;
  }
};


export const addRecruiter = async (user) => {
  try {
    const response = await api.post(`/signup`, {
      username: user.username,
      email: user.email,
      mobile: user.mobile,
      user_role: "recruiter",
      signup_type: "admin",
    });

    return response.data;
  } catch (error) {
    if (error.response && error.response.data) {
      return {
        signup: "user_exists",
        message: error.response.data.message,
      };
    }
    throw error;
  }
};

export const validateManagers = async (id, type) => {
  try {
    const response = await api.post("cam_rem_validation", {
      id: id,
      user_type: type,
    });
    return response.data;
  } catch (error) {
    error.message = error.response && error.response.data.message;
    throw error;
  }
};
