import React, { useRef, useState } from "react";
import {
  Box,
  Button,
  ChakraProvider,
  extendTheme,
  Textarea,
  VStack,
  FormControl,
  FormLabel,
  Text,
  Input,
  Checkbox,
  useToast,
  useColorModeValue,
  Stack,
  SimpleGrid,
  Link,
} from "@chakra-ui/react";
import { FaComments, FaTimes } from "react-icons/fa";
import api from "../config/axios";

const theme = extendTheme({
  fonts: {
    body: "Arial, sans-serif",
  },
});

const ChatBot = () => {
  const chatRef = useRef(null);
  const [chatHistory, setChatHistory] = useState([]);
  const [chatOpen, setChatOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [queryType, setQueryType] = useState(""); // Added state for query type
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const toggleChat = () => setChatOpen((prevOpen) => !prevOpen);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!queryType) {
      toast({
        title: "Please select a query type.",
        status: "warning",
        duration: 2000,
        isClosable: true,
        position: "bottom",
      });
      setIsLoading(false);
      return;
    }

    const payload = {
      email,
      mobile_no: phone,
      query: queryType,
      message,
    };

    try {
      // First API call using axios
      const response = await api.post(
        "/submit_customer_service_query/",
        payload
      );

      if (response.status === 200) {
        toast({
          title: "Submitted Successfully!",
          status: "success",
          duration: 2000,
          isClosable: true,
          position: "bottom",
        });

        // Second API call for sending the email
        const emailPayload = { email };
        const emailResponse = await api.post(
          "/send_customer_support_email",
          emailPayload
        );

        if (emailResponse.status === 200) {
          toast({
            title: "Confirmation email sent!",
            status: "success",
            duration: 2000,
            isClosable: true,
            position: "bottom",
          });
        } else {
          console.error("Email sending failed:", emailResponse);
          toast({
            title: "Failed to send email.",
            description: "Please check your email and try again.",
            status: "error",
            duration: 2000,
            isClosable: true,
            position: "bottom",
          });
        }

        // Clear form fields
        setEmail("");
        setPhone("");
        setMessage("");
      } else {
        console.error("Form submission failed:", response);
        toast({
          title: "Submission Failed.",
          description: "Please try again.",
          status: "error",
          duration: 2000,
          isClosable: true,
          position: "bottom",
        });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      toast({
        title: "An error occurred.",
        description: "Please try again later.",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "bottom",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box position="fixed" bottom="20px" right="20px" zIndex={9999}>
      <Button
        size="md"
        color="white"
        bg="#3B58D7"
        leftIcon={chatOpen ? <FaTimes /> : <FaComments />}
        onClick={toggleChat}
        marginBottom={2}
      >
        {chatOpen ? "Close" : "Chat"}
      </Button>

      {chatOpen && (
        <Box
          ref={chatRef}
          width="300px"
          border="1px solid"
          borderColor={useColorModeValue("gray.200", "gray.700")}
          borderRadius="md"
          boxShadow="md"
          padding={3}
          position="fixed"
          bottom="70px"
          right="20px"
          bg={useColorModeValue("white", "gray.800")}
          maxHeight="600px"
          overflow="hidden"
        >
          <Box borderBottom="1px solid" borderColor={useColorModeValue("gray.200", "gray.700")} marginBottom={4}>
            <Box fontWeight="bold" fontSize="xl" textAlign="center" color={useColorModeValue("gray.800", "white")}>
              Customer Service
            </Box>
          </Box>

          <VStack
            spacing={2}
            align="flex-start"
            maxHeight="300px"
            overflowY="auto"
          >
            {chatHistory.map((chat, index) => (
              <Box
                key={index}
                p={2}
                borderRadius="md"
                backgroundColor={chat.type === "user" ? useColorModeValue("gray.100", "gray.700") : useColorModeValue("blue.100", "blue.700")}
                alignSelf={chat.type === "user" ? "flex-start" : "flex-end"}
                color={useColorModeValue("gray.800", "white")}
              >
                {chat.content}
              </Box>
            ))}
          </VStack>

          <form onSubmit={handleSubmit}>
            <VStack spacing={4} align="flex-start" mt={4}>
              <FormControl>
                <FormLabel color={useColorModeValue("gray.800", "white")}>Email</FormLabel>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  required
                  placeholder="Enter your email"
                  bg={useColorModeValue("white", "gray.700")}
                  color={useColorModeValue("gray.800", "white")}
                />
              </FormControl>
              <FormControl>
                <FormLabel color={useColorModeValue("gray.800", "white")}>Mobile</FormLabel>
                <Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  type="tel"
                  required
                  maxLength={10}
                  minLength={10}
                  placeholder="Enter your phone number"
                  bg={useColorModeValue("white", "gray.700")}
                  color={useColorModeValue("gray.800", "white")}
                />
              </FormControl>
              <Text color={useColorModeValue("gray.800", "white")}>
                Hi! Let us know how we can help, and we’ll respond shortly.
              </Text>

              <FormControl>
                <SimpleGrid columns={2} spacing={4}>
                  <Checkbox
                    size="md"
                    name="query"
                    colorScheme="red"
                    value="Getting Started"
                    isChecked={queryType === "Getting Started"}
                    onChange={() => setQueryType("Getting Started")}
                    color={useColorModeValue("gray.800", "white")}
                  >
                    Getting Started
                  </Checkbox>
                  <Checkbox
                    size="md"
                    name="query"
                    colorScheme="orange"
                    value="Access Related"
                    isChecked={queryType === "Access Related"}
                    onChange={() => setQueryType("Access Related")}
                    color={useColorModeValue("gray.800", "white")}
                  >
                    Access Related
                  </Checkbox>
                  <Checkbox
                    size="md"
                    name="query"
                    colorScheme="green"
                    value="Payment Related"
                    isChecked={queryType === "Payment Related"}
                    onChange={() => setQueryType("Payment Related")}
                    color={useColorModeValue("gray.800", "white")}
                  >
                    Payment Related
                  </Checkbox>
                  <Checkbox
                    size="md"
                    name="query"
                    colorScheme="blue"
                    value="Technical Support"
                    isChecked={queryType === "Technical Support"}
                    onChange={() => setQueryType("Technical Support")}
                    color={useColorModeValue("gray.800", "white")}
                  >
                    Technical Support
                  </Checkbox>
                </SimpleGrid>
              </FormControl>

              <FormControl>
                <FormLabel color={useColorModeValue("gray.800", "white")}>Your Message</FormLabel>
                <Textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  placeholder="Enter your message"
                  bg={useColorModeValue("white", "gray.700")}
                  color={useColorModeValue("gray.800", "white")}
                />
              </FormControl>

              <Box mt={4}>
                <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
                  Visit{" "}
                  <Link
                    href="/Faq"
                    color="#3B58D7"
                    fontWeight="bold"
                    isExternal
                  >
                    FAQ page
                  </Link>{" "}
                  for more help.
                </Text>
              </Box>

              <Button
                color="white"
                type="submit"
                bg="#3B58D7"
                isLoading={isLoading}
              >
                Submit
              </Button>
            </VStack>
          </form>
        </Box>
      )}
    </Box>
  );
};

const App = () => (
  <ChakraProvider theme={theme}>
    <ChatBot />
  </ChakraProvider>
);

export default App;